@font-face {
  font-family: 'Inter-Regular';
  src: local('Inter Regular'), local('Inter-Regular'),
    url('./Inter/Inter-Regular.woff2') format('woff2'),
    url('./Inter/Inter-Regular.woff') format('woff'),
    url('./Inter/Inter-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter-Medium';
  src: local('Inter Medium'), local('Inter-Medium'),
    url('./Inter/Inter-Medium.woff2') format('woff2'),
    url('./Inter/Inter-Medium.woff') format('woff'),
    url('./Inter/Inter-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Inter-SemiBold';
  src: local('Inter Semi Bold'), local('Inter-SemiBold'),
    url('./Inter/Inter-SemiBold.woff2') format('woff2'),
    url('./Inter/Inter-SemiBold.woff') format('woff'),
    url('./Inter/Inter-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter-Bold';
  src: local('Inter Bold'), local('Inter-Bold'),
    url('./Inter/Inter-Bold.woff2') format('woff2'),
    url('./Inter/Inter-Bold.woff') format('woff'),
    url('./Inter/Inter-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
